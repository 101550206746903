.btn-custom {
    color: #333 !important;
    font-size: 20px !important;
    padding: 0 !important;
}

@media screen and (max-width: 768px) {

input.custom-input.border-start-0.form-control {
    padding: 0.3rem 0.75rem !important;
}
}