.latest-product-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
}

.swiper-custom-button-prev,
.swiper-custom-button-next {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: 1px solid #666 !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.swiper-custom-button-prev:hover,
.swiper-custom-button-next:hover {
  background-color: rgba(255, 255, 255, 0.9); 
}

.arrow-icon{
    color:#333;
}

.swiper-custom-button-prev {
  left: 10px; 
}

.swiper-button-next {
  right: 10px; 
}

.swiper-button-disabled {
    border-color: #e5e5e5c4 !important;
    cursor: not-allowed;
}
.navigation-style{
    gap:15px;
}