.btn.btn-gray, .btn.btn-gray:hover {
    background: #cfd8dc;
}



.testi-lable .form-label {
    color: #e8ecee !important;
}

.testi-box .form-control {
    background: #fff0;
}

  /* testimonial card */

  .testi-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

  .testimonial-card {
    border: 2px outset #882920;
    border-radius: 0px;
    padding: 6px;
  }

.testimonial-details {
  padding: 10px;
}

.star-icon {
  color: #FFC107;
  font-size: 16px;
}
.date-icon {
  font-size: 16px;
  color:#333;
}
.testimonial-details {
  padding: 10px;
  font-size: 16px;
  line-height: 24px;
  font-family: poppins;
  font-weight: 300;
  color: #666;
}
.published {
  font-size: 14px;
  color: #666;
  font-weight: 400;
}



@media only screen and (max-width: 1050px) {

.testi-lable {
    width: 100%;
}
}

@media only screen and (max-width: 580px) {

.testi-list {
    grid-template-columns: 1fr;
    gap: 10px;
}

.testimonial-details {
    font-size: 14px;
}
}