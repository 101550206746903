
.custom-select {
    position: relative;
    width: 200px;
  }
  
 
  .select-selected {
    background-color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 0px;
    border: 1px solid #d0d0d0;
    font-weight: 500;
    color: #333;
    font-size: 16px;
  }
  
  .select-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
  
  .select-arrow-custom {
    position: absolute;
    top: 70%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
  }
  

  .select-items {
    position: absolute;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    border-top: none;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 2;
    border-radius: 0px;
    margin-top: -1px;
    font-size: 15px;
    font-family:poppins;

  }
  

  .select-item {
    padding: 10px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 300;
    color: #666;
    border-bottom: 1px solid #d9d9d96e;
    transition: all 0.3s linear;
    font-family:poppins;
  }
  

  .select-item:hover {
    background-color: #d46a15;
    color: #fff;
  }
  
  .label-style {
    position: absolute;
    left: 20px;
    top: -10px;
    background: #ffff;
    padding: 1px 5px;
    font-size: 13px;
    color:#666;
    z-index:99;
}

  /* ::placeholder {
    font-size: 16px;
    font-weight: 400;
  } */
  
  @media screen and (width <= 770px) {
    .select-items {
      max-height: 150px;
    }
    .custom-select {
      width: 150px;
  }

  }
  
  @media screen and (width <= 767.5px) {
    .select-selected {
      padding: 10px 15px;
      font-size: 14px;
  }
  .custom-select {
    width: 200px;
}
  }
  
  @media screen and (width <= 576px) {
    .custom-select {
      position: relative;
      width: 100%;
      margin-bottom:15px;
    }
  }
  

 