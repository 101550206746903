.address-box {
    border: 1px solid #d8dfe2;
}

.address-box h3 {
    font-family: 'Poppins';
    font-size: 16px;
    color: #333;
    line-height: 26px;
    font-weight: 500;
}

.address-box p {
    font-size: 14px;
    color: #333;
    font-family: 'Poppins';
    font-weight: 400;
    margin-bottom: 5px;
    line-height: 20px;
}

.checkout-address {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width:1025px){
    .checkout-address {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width:992px){
    .checkout-address {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width:590px){

.checkout-address {
    grid-template-columns: 1fr;
}
}