.modal {
  background: #000000b0;
}
.video-thumbnail {
  position: relative;
  cursor: pointer;
  transition: all 0.3s linear;
  overflow: hidden;
}

.video-thumbnail img {
  border-radius: 14px;
  transition: all 0.3s linear;
}

.video-thumbnail:hover img {
  transform: scale(1.1);
}

.play-icon {
  position: absolute;
  top: 45%;
  left: 45%;
  background: #fff;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s linear;
  z-index: 1;
}

.play-icon:hover {
  background: #770a00;
}

.play-icon:hover .play {
  color: #fff;
}

.play-icon svg {
  color: #770a00;
  font-size: 18px;
}

.video-modal {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background-color: #fff;
  padding: 20px;
  border: 2px solid #000;
  outline: none;
}

.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* .btn-close {
  color: #fff;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 1rem;
  filter: invert(1);
} */

.modal-content {
  border: none !important;
}

.event__thumb::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  content: "";
  height: 100%;
  opacity: 0.502;
  background-image: linear-gradient(
    360deg,
    #770a0063 0%,
    rgb(250 250 250 / 27%) 100%
  );
}

.content-thumbnail {
  overflow: hidden;
}

.content-thumbnail img {
  transition: all 0.3s linear;
  border-radius: 2px;
  height: 480px;
  object-fit: cover;
  object-position: top;
}

.content-thumbnail:hover img {
  transform: scale(1.1);
}

.banner-box img {
  border-radius: 0px;
  height: 300px;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  transition: all 0.3s linear;
}

.lightbox-content {
  max-width: 100%;
  max-height: 100%;
  background: #fff;
  padding: 12px;
  position: relative;
  border-radius: 4px;
}

.lightbox-content img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.lightbox-content button {
  position: absolute;
  top: -25px;
  right: -18px;
  background: none;
  border: none;
  color: #770a00;
  font-size: 42px;
  cursor: pointer;
  font-weight: 300;
}

.lightbox-content button svg {
  background: #fff;
  border-radius: 50%;
  padding: 2px;
}

.lightbox-content h3 {
  font-family: poppins;
  font-size: 18px;
  color: #333;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 500;
}

@media screen and (max-width: 1100px) {
  .play-icon {
    top: 25%;
    left: 40%;
    height: 60px;
    width: 60px;
  }
}

@media screen and (max-width: 1025px) {
.play-icon {
  top: 45%;
  left: 45%;
}
}


@media screen and (max-width: 768.5px) {
  .play-icon {
    top: 45%;
    left:45%;
    height: 60px;
    width: 60px;
  }
}

@media screen and (max-width: 767.5px) {
  .video-thumbnail {
    margin-bottom: 24px;
  }

  .mtop-2 {
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .video-thumbnail {
    margin-bottom: 15px;
  }
  .lightbox-content {
    margin: 15px;
}
iframe {
  width: 100%;
  height: 250px;
}
.play-icon {
  left: 45%;
}
.content-thumbnail img {
  height: 380px;
}
}

@media screen and (max-width: 400px) {
  .date__widget ul li {
    font-size: 12px;
  }
  .content-thumbnail img {
    height: 350px;
  }
}
