.hairclub-heading {
    font-size: 18px;
    font-family: poppins;
    font-weight: 600;
    color: #fff;
    text-transform: capitalize;
    line-height: 24px;
}

.form .input-group-text {
    color: #ffffff;
    background-color: #d46a15;
    border: var(--bs-border-width) solid #d46a15;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Poppins';
}

.hairclub-form{
    background: #d46a150a;
    padding: 20px 20px 20px 20px;
}

.hairclub-form {
    padding: 20px 20px 20px 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-attachment: fixed;
    background-repeat: repeat;
    background-position: 50% -15.2416px;
    background-size: cover;
    background-image: url('../../assets/hair-club.jpg');
}

.form-bg {
    width: 95%;
    margin: 0 auto;
    background: #ffffff29;
    padding: 20px;
    backdrop-filter: blur(8px);
    border-radius:4px;
}

.form label {
    color: #fff;
    font-size:14px;
}

.hairclub-container p {
    font-size: 16px;
    color: #666;
    line-height: 24px;
    font-family: 'Poppins';
    font-weight: 300;
}

.hairclub-container h4 {
    font-size: 20px;
    color: #333;
    font-family: 'Poppins';
    line-height: 24px;
    text-transform: capitalize;
    font-weight: 600;
}

.hairclub-list {
    list-style: none;
    padding: 0;
}

.hairclub-list li {
    padding: 14px 10px;
    color: #666;
    font-size: 16px;
    background: #f0f0f082;
    margin-bottom: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 20px;
}

.font-20{
    font-size:20px
}

.hair-club-gallery {
    padding-left: 30px;
}

.hair-club-gallery .main-slider {
    margin-bottom: 15px;
}

.text-borwn {
    color: #C75E0A;
}


@media only screen and (max-width: 1050px) {
    
.form-bg {
    width: 100%;
}
.hair-club-gallery {
    padding-left: 0px;
}
}

@media only screen and (max-width: 992px) {

.hairclub-container p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
}

.hair-club-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
}

.hairclub-list li {
    font-size: 14px;
}

}

@media only screen and (max-width: 600px) {

    .hair-club-grid {
        display: grid;
        grid-template-columns: 1fr;
    }
    
    }