.accordion-button:not(.collapsed) {
    color: #ffffff !important;
    background-color: #d46a15 !important;
    box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    font-weight: 500;
    font-size: 18px;
}

.accordion-button::after {
    color: #fff;
    filter: invert(1);
}



.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: #ffffff !important;
    text-align: left;
    background-color: #3c3436 !important;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
    font-weight: 500;
    font-size: 18px;
}

.accordion-body p,.accordion-body a {
    font-size: 14px;
}

.table th{
    font-weight:500 !important;
}

.css-13cymwt-control, .css-13cymwt-control:hover {
    border-color:#D46A15 !important;
    box-shadow:0 0 1px #D46A15 !important
}

.css-qbdosj-Input{
    padding: 0.5rem 0.75rem !important;
}

@media only screen and (max-width: 500px) {

.accordion-body p, .accordion-body a {
    text-wrap: pretty;
    word-break: break-word;
}
}