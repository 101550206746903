.blog-img{
  height:430px;
 }
 
 
.blog-img img {
  width: 100%;
  transition: all 2s;
  height:inherit;
  object-fit: cover;
}

.blog-inner {
  position: relative;
  overflow: hidden;
}

.blog-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
  background-color: #3c3436b5;
}

.blog-date-info {
  padding: 20px 20px;
  font-size: 18px;
  color: #fff;
  text-align: dec;
}
.blogcard-date {
  display: block;
  font-size: 14px;
  color: #fff;
}

.blog-text {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 20px 20px;
}



.bdl-text a {
  text-decoration: none;
}
.blog-text h1 {
  font-size: 20px;
  font-family: "poppins";
  margin-bottom: 24px;
  color: #e1e1e1;
  font-weight: 400;
}

.bdl-text svg {
  color: #fff;
  font-size: 30px;
  transition: all 2s;
  display: block;
}

.blog-inner:hover .bdl-text svg {
  transform: translateX(20px);
  transition: all 2s;
}

.blog-inner:hover .blog-img img {
  transform: scale(1.1);
  transition: all 2s;
}

@media screen and (max-width: 1300px) {

.blog-img {
  height: 400px;
}

}


@media screen and (max-width: 1025px) {

  .blog-text h1 {
    font-size: 18px;
   }
  }

