
.bill-deatil p {
    font-size: 16px;
    margin: 0;
    text-transform: capitalize;
    color: #666;
    line-height: 24px;
    font-family: 'Poppins';
    font-weight: 400;
}

.billing-product-detail {
    display: grid;
    grid-template-columns: .5fr 1fr;
    grid-column-gap: 100px;
}

.table tr, .table tr td{
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    font-family: 'Poppins';
    color: #666;
    font-size: 14px;
}

.table tr th{
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    font-family: 'Poppins';
    font-weight:500;

}

/* paymentcard */

.card-details {
    background: #f0f2f4;
    padding: 20px;
}

.payment-box {
    background: #cfd8dc52;
    padding: 10px;
    border-radius: 3px;
}

.payment-box h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    font-family: poppins;
    color: #333;
}

.prod-width{
    width:80px;
}


.bill-deatil p {
    font-size: 14px;
    margin: 0;
    text-transform: capitalize;
    color: #666;
    line-height: 24px;
    font-family: 'Poppins';
    font-weight: 400;
}

.fa-18x {
    font-size:18px;
}

@media screen and (max-width:1300px) {

.bill-deatil p {
    font-size: 14px;
}
}



@media screen and (max-width:576px) {
.card-details {
    padding: 5px;
}

.fa-18x {
    font-size:14px;
}
}
