.sec3.commn-layout {
    background-size: cover;
    background-position: left center;
    padding:5rem 0 11rem 0
}
.sec3 .left-content h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 175%;
    color: #fff;
    font-family: 'Poppins';
    background: #0d47b6;
    display: inline;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    padding: 6px 10px 1px;
}

.sec3 .left-content p {
    font-style: normal;
    font-weight: 400;
    font-family: 'Poppins';
    font-size: 15px;
    line-height: 170%;
    color: #fff;
    margin: 30px 0 0;
}

.sec3 img {
    position: relative;
    top: -25px;
}

.accp-box {
    background: #fff;
    width: 335px;
    padding: 40px 30px 34px 44.4px;
    border-radius: 44px;
    position: absolute;
    right: 190px;
    margin-top: -40px;
}

.accp-box:before {
    content: "";
    width: 90px;
    height: 72px;
    position: absolute;
    background: #fff;
    top: -10px;
    left: -14px;
    clip-path: polygon(85% 16%, 0 0, 16% 100%);
}

.accp-box p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 170%;
    color: #4e4e4e;
}

::-webkit-scrollbar-track{
    background: white;
}

::-webkit-scrollbar-thumb{
    background: #fff;
    border-radius: 10px;
}

div:hover::-webkit-scrollbar-thumb{
    background: #fff;
}

::-webkit-scrollbar-thumb:hover {
    background: #fff;
}

@media screen and (width <= 700px) {

.text-bg-image img {
    height: 360px;
    object-fit: cover;
}
}