.about-info p {
    font-size: 16px;
    color: #333;
    font-weight: 200;
    font-weight: 300;
    line-height: 24px;
    font-family: 'Poppins';
}

@media only screen and (max-width: 1050px) {
    .about-info p {
        font-size: 14px;
    }
}