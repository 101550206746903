

.main-slider {
  display: grid;
  place-items: center;
  border: 1px solid #efefef;
  box-shadow: 1px 8px 20px -12px #0000001f;
}



@keyframes pulse {
  0% {
    transform: translateY(0deg);
  }

  to {
    transform: translateY(-6px);
  }
}

.container-slide {
  position: relative;
  overflow: hidden;
}

.image-box {
  aspect-ratio: 1/1.1;
  transition: all 0.3s linear;
}



.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.experince-content {
  background: #dddddd70;
}

.experince-content p {
  color: #333;
  font-family: "Poppins";
  line-height: 28px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
}

.experince-content p:nth-child(2) {
    font-size: 14px;
    color: #8d8d8d;
    font-weight: 700;
}
.image-before {
  position: absolute;
  inset: 0;
  width: 50%;
}

.slider {
  position: absolute;
  inset: 0;
  cursor: pointer;
  opacity: 0; 
  width: 100%;
  height: 100%;
}

.slider:focus-visible ~ .slider-button {
  outline: 5px solid black;
  outline-offset: 3px;
}

.slider-line {
  position: absolute;
  inset: 0;
  width: 0.2rem;
  height: 100%;
  background-color: #fff;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

.slider-button {
  position: absolute;
  background-color: #fff;
  color: black;
  padding: 0.5rem;
  border-radius: 100vw;
  display: grid;
  place-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  box-shadow: 1px 1px 1px hsl(0, 50%, 2%, 0.5);
  width: 100px;
  height: 100px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.image-container:hover .slider-button {
  background: #18b0f4;
  color: #fff;
}

p.portfo {
  color: #18b0f4 !important;
  font-family: "Poppins";
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
}

.gallery-content p {
  color: #333;
  font-family: "Poppins";
  line-height: 30px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  
}

.bg-light-gray{
background: #f9f9f9;
}

.gallery-content h3 {
  font-size: 48px;
  color: #333;
  font-weight: bold;
  line-height: 48px;
  font-family: 'Poppins';
  text-transform: capitalize;
  text-align: center;
}


.gallery-para-width {
  width: 1000px;
}

.navigation-userexp {
  position: absolute;
  top: -475px;
  width: 100%;
  z-index: 1;
}

@media screen and (max-width: 1500px) {
  .gallery-content p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1500px) {
  .slider-button {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 1400px) {
.gallery-content h3 {
  font-size: 40px;
  line-height: 40px;
}
}

@media screen and (max-width: 1250px) {
  .gallery-content h3 {
    font-size: 36px;
  }

  .gallery-content p {
    font-size: 14px;
  }

  .gallery-content {
    padding-top: 0px;
  }
}

@media screen and (max-width: 991.5px) {
  .gallery-content {
    margin-top: 10px;
  }

  .experince-content p {
    font-size: 14px;
    line-height: 26px;
}
 
}

@media screen and (max-width: 770px) {
  .gallery-content p {
    line-height: 24px;
    font-size: 14px;
  }
  .gallery-para-width {
    width: 100%;
  }
  
}

@media screen and (max-width: 768px) {
  .gallery-content h3 {
    font-size: 24px;
    line-height:30px;
  }
}

@media screen and (max-width: 600px) {
  .slider-button {
    width: 80px;
    height: 80px;
  }
}
