.nav-tabs .nav-link {
    margin-bottom: calc(-1* var(--bs-nav-tabs-border-width));
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius:0;
    background: #e8edef;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: #fff0;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #fff !important;
    background-color: #3c3436 !important;
    border-color: #fff  !important;
}

.nav-tabs{
    border-bottom:none !important;
}

.nav-tabs .nav-link {
    border-radius: 4px !important;
}
.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
    font-size: 17px !important;
    font-weight:500 !important;
}

.tab-content h3 , .tab-content h1 , .tab-content h2 , .tab-content h4{
    font-size: 22px;
    color: #333;
    line-height: 24px;
    font-family: 'Poppins';
    font-weight: 500 !important;
    text-transform: capitalize;
}

.tab-content p {
    font-size: 16px;
    color: #666;
    line-height: 24px;
    font-family: 'Poppins';
    font-weight: 300;
    text-transform: capitalize;
}

.tab-content {
    margin-top: 30px;
}


@media screen and (max-width: 1180px) {

.nav-item {
    margin: 0 10px 0 0;
}

}


@media screen and (max-width: 770px) {

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
    font-size: 14px !important;
    font-weight: 500 !important;
}
}

@media screen and (max-width: 1200px) {

    .tab-content h3, .tab-content h1, .tab-content h2, .tab-content h4 {
      font-size: 20px;
    }
   }
  
    @media screen and (max-width: 1024px) {
        .comment-meta p {
            color: #fff !important;
            font-size: 12px !important;
        }
    .tab-content h3, .tab-content h1, .tab-content h2, .tab-content h4 {
      font-size: 18px;
  }

  .nav-justified > .nav-link, .nav-justified .nav-item {
    text-wrap: nowrap;
}
  }


  @media screen and (max-width: 480px) {
  ul#fill-tab-example button {
    margin-bottom: 10px;
}
  }
