.navigation-slide {
  position: absolute;
  top: -335px;
  width: 100%;
  z-index: 1;
}

.prod-slide {
  margin: 0 auto;
  text-align: center;
}

.prod-detail-card h3 {
  font-size: 24px;
  color: #333;
  font-weight: 500;
  line-height: 32px;
  font-family: "Poppins";
}

.prod-detail-card p {
  font-size: 16px;
  color: #666;
  line-height: 24px;
  font-family: "Poppins";
}

.rating-box {
  background: #3c3436;
  color: #fff;
  font-size: 12px;
  padding: 3px;
  border-radius: 4px;
}

.sky-blue-text {
  color: #0098df;
}

.prod-price-text {
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #d46a15 !important;
}

.btn-wishlist {
  color: #d46a15;
  border: 0;
  padding: 0px;
  font-size: 16px;
  background: #fff;
  border-radius: 0px;
  font-weight: 500;
}



.product_socials {
  font-weight: 500;
  display: flex;
  gap: 10px;
  margin-top: 20px;
  font-size: 16px;
  align-items: center;
  color: #666;
}

.socials-inline {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 10px;
  }
.socials-inline li a  , .socials-inline li{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #fff;
}

.socials-inline li:nth-child(1) {
    background: #4267b2;
}

.socials-inline li:nth-child(2) {
    background: #5bb6ff;
}

.socials-inline li:nth-child(3) {
    background: #0d69b2;
}

.socials-inline li:nth-child(4) {
    background: #0bc041;
}

.text-green {
    color: #018306;
}

/* product slider */


.swiper-wrapper {
    padding: 10px 0;
  }
  .product-images-slider .swiper-slide {
    padding-top: 70%;
    overflow: hidden;
    position: relative;
  }
  .product-images-slider .swiper-slide img {
    position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      margin: 0 auto;
      right: 0;
  }
  .product-images-slider .swiper-button-prev {
    left: 30;
    color: #5f5f5f;
  }
  .product-images-slider .swiper-button-next {
    right: 30;
    color: #5f5f5f;
  }
  .product-images-slider-thumbs .swiper-slide {
    cursor: pointer;
    border: 1px solid #dddbdb;
    width: 100px !important;
    height: 75px !important;
    width:75px !important;
    margin-bottom: 5px;
  }
  .product-images-slider-thumbs .swiper-slide-thumb-active {
    border-color: #d46a42;
  }
  
  
  .product-images-slider-thumbs-wrapper {
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    position: relative;
    object-fit: cover;
}
  .product-images-slider-thumbs-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    right: 0;
    margin: 0 auto;
    padding:14px;
  }
  
  .product-img{
  position: relative;
  overflow: hidden;
  }


  /* product info icon */

 .mad-icon-boxes {
    display: flex;
    flex-wrap: wrap;
}

.mad-icon-box {
  column-gap: 1rem;
  align-items: flex-start;
}

.mad-icon-boxes {
  display: flex;
  column-gap: 2rem;
  row-gap: 1rem;
}

.mad-icon-box i {
  display: inline-flex;
  position: relative;
  z-index: 1;
}

.mad-icon-box {
  column-gap: 1.2rem;
  align-items: flex-start;
  display: flex;
}

.mad-icon-box-icon svg {
  font-size: 32px;
}

.mad-icon-box i:after {
  content: '';
    position: absolute;
    top: -6px;
    right: -6px;
    width: 2rem;
    height: 2rem;
    background: #d46a1538;
    border-radius: 50%;
    z-index: -1;
}

.mad-icon-box-title {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Poppins';
  text-transform: capitalize;
  margin-bottom:2px;
}

.mad-icon-box-content p {
  font-size: 14px;
  margin-bottom: 0;
  color: #666;
  line-height: 24px;
}

@media screen and (max-width: 1700px) {
.navigation-slide {
  top: -280px;
}
}

@media screen and (max-width: 1500px) {
  .navigation-slide {
    top: -250px;
  }
  }


  
@media screen and (max-width: 1370px) {
  .navigation-slide {
    top: -235px;
  }
  .prod-detail-card h3 {
    font-size: 22px;
  }

  }


  @media screen and (max-width: 1025px) {
  .prod-detail-card h3 {
    font-size: 18px;
    line-height: 28px;
  }
  .mad-icon-box-title {
    font-size: 14px;
}
.mad-icon-box-content p {
  font-size: 13px;
}
  }


  @media screen and (max-width: 767.5px) {
    .navigation-slide {
        top: -280px;
    }
}

@media screen and (max-width: 550px) {
  .navigation-slide {
    top: -240px;
}
}

@media screen and (max-width: 450px) {

  .navigation-slide {
    top: -225px;
}
}