.prod-img {
    width: 150px;
    margin: 0 auto;
    object-fit: cover;
}

.prod-price {
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    line-height: 20px;
    color:#333;
    font-family:poppins;

}

.price-light {
    font-size: 13px;
    text-align: center;
    font-weight: 300;
    line-height: 20px;
    color:#8f8f8f;
    font-family:poppins;
}

.prod-card {
    border: 1px solid #e1e1e1;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom:2px;
}

.prod-details {
    border-top: 1px solid #ddd;
    padding:10px;
}

.prod-card-img{
padding:25px;
position: relative;
}

.product-info {
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    padding: 0 12px;
}

.out-of-stock-box{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.out-of-stock {
    background: #e2000f;
    color: #fff;
    line-height: 14px;
    height: 19px;
    border-radius: 4px;
    font-size: 11px;
    padding: 3px;
    font-weight: 500;
}

.wishlist {
    font-size: 24px;
    color: #FFEB3B;
}

.line-through{
    text-decoration:line-through;
}

.prod-title {
    font-size: 13px;
    color: #333;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 20px;
    text-decoration:none;
}

.rating-star {
    background: #F8F2F2;
    color: #333;
    font-size: 12px;
    padding: 3px;
    border-radius: 4px;
}

.star-icon {
    color: #BA0107;
}

.prod_cart {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #b4b4b42e;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:all 0.3s linear;
    color:#000;
}

.prod_cart:hover {
    background: #d46a15 ;
    color:#fff;
}

.prod_cart svg { 
    font-size:18px;
}

.member-text {
    font-size: 11px;
    color: #d46a15;
    font-weight: 600;
    line-height: 20px;
    flex: 1;
}