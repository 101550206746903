.footer {
  background: #1f1b1c;
}

.footer-about {
  border-right: 1px solid #383838;
  padding-right: 3rem;
  height: 100%;
}

.footer-about h3 {
  font-size: 24px;
  color: #ffff;
  font-weight: 400;
  font-family: "Poppins";
  line-height: 32px;
}

.footer-about p {
  font-size: 14px;
  color: #ffff;
  font-weight: 200;
  font-family: "Poppins";
  line-height: 25px;
}

.footer-link h3 {
  font-size: 18px;
  color: #fff;
  font-family: poppins;
  font-weight: 500;
  text-transform: capitalize;
}

.footer-link ul,
.footer-second-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-logo {
  width: 200px;
}

.footer-link ul li,
.footer-second-list ul li {
  padding: 3px 10px 3px 0;
  color: #fff;
  font-size: 13px;
  line-height: 23px;
  font-weight: 300;
}

.footer-link ul li a {
  text-decoration: none;
  color: #fff;
}

.footer-second-list ul li a {
  text-decoration: none;
  color: #b0b0b0;
}

.phoneIcon {
  transform: rotate(45deg);
}
.h-57 {
  height: 57px;
}

.arrowIcon {
  font-size: 36px;
  width: 60px;
}

.footer-strip {
  background: #d46a15;
  border-radius: 8px;
  padding: 14px;
  display: grid;
  grid-template-columns: 1fr 200px;
}

.footer-strip h3 {
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  line-height: 24px;
  font-weight: 500;
}

.footer-strip p {
  font-size: 14px;
  color: #fff;
  line-height: 24px;
  font-weight: 300;
}

.border-left-white-1 {
  border-left: 1px solid #e0a678;
}

.payment-strip p {
  font-size: 13px;
  color: #f9f9f9;
  font-weight: 300;
  font-family: "Poppins";
}

@media screen and (max-width: 1330px) {
.footer-strip p {
    font-size: 12px;
    line-height: 24px;
}
.footer-about {
    padding-right: 2rem;
}
}

@media screen and (max-width: 1250px) {
.footer-strip h3 {
    font-size: 16px;
}
.footer-strip {
    grid-template-columns: 1fr 120px;
}
}

@media screen and (max-width: 1150px) {

.footer-about h3 {
    font-size: 20px;
    line-height: 30px;
}
.footer-about p {
    font-size: 14px !important;
}
}


@media screen and (max-width: 770px) {
  .footer-about {
    border-right: 1px solid #38383800;
    padding-right: 0rem;
  }

  .footer-strip p {
    font-size: 12px;
    line-height: 20px;
  }

  .footer-strip h3 {
    font-size: 16px;
    color: #fff;
  }
}

@media screen and (max-width: 767.5px) {
.footer-strip {
    grid-template-columns: 1fr;
    width: 100%;
}
.footer-about h3 {
    font-size: 18px;
    line-height: 25px;
}
.footer-about p {
    font-size: 14px !important;
   
}
}

@media screen and (max-width: 600px) {
  .footer-logo {
    width: 80px;
    margin-bottom: 20px;
  }

  

}
