.header-contact-body {
	align-items: stretch;
	display: flex;
	justify-content: space-between;
	min-height: 100%;
	width: 100%;
}

.header-contact-form {
    padding: 40px;
    width: 50%;
    background: #cfd8dc6e;
}

.header-contact-form p {
    font-size: 16px;
    color: #666;
    line-height: 24px;
    font-family: 'Poppins';
    font-weight: 300;
}

.header-contact-form .form-body {
	background-origin: border-box;
	width: 100%;
	border: 8px solid #c3c3c36b;
	padding: 30px;

}


.header-contact-information {
	width: 50%;
}

.information-cells {
	align-items: stretch;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	min-height: 100%;
	width: 100%;
}


.information-cells .information-cell {
	align-items: flex-start;
	background: #3c3436;
	border-left: 1px solid #fff3f3;
	border-top: 1px solid #fff3f3;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 40px;
	position: relative;
	transition: .3s;
	width: 50%;
}

.information-cells .information-cell__icon {
	align-items: center;
	display: flex;
	justify-content: center;
	margin-bottom: 12px;
}

.information-cells .information-cell__title {
	display: block;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.6;
    margin-bottom: 6px;
    color: #eaeef0;
    font-family: 'Poppins';
}

.information-cells .information-cell__link {
	display: block;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.6;
	margin-bottom: 12px;
	max-width: 100%;
	transition: .3s;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	color: #eaeef0;
	font-family: 'Poppins';
    text-decoration: none;
}

.information-cells .information-cell__text {
	font-size: 16px;
	font-weight: 600;
	font-weight: 400 !important;
	letter-spacing: normal;
	line-height: 1.6;
	font-family: 'Poppins';
}

.information-cells .information-cell--green .information-cell__text {
	color: #00d0bb;
}

.information-cell.information-cell--wide {
	width: 100%;
}

.icon svg {
	font-size: 30px;
	color: #ffff;
	padding: 0;
}

.information-cell.information-cell--wide {
	padding: 0;
}

.information-cell.information-cell--wide img {
	width: 100%;

}

.input-login {
	border: 1px solid #cecece;
	padding: 10px 14px;
	margin-bottom: 20px;
}


.button-login {
	background-color: #333333;
	margin-top: 10px;
	width: fit-content;

}

.border-custom {
    border: 1px solid #ced4da !important;
}

.form-inner {
	min-height: 400px;
	position: relative;
}

@media screen and (max-width:1300px) {
	.information-cells .information-cell__link {
		font-size: 14px;
	}
    .header-contact-form {
        padding:30px;
    }
}

@media screen and (max-width:1030px) {
	.header-contact-form .form-title {
		font-size: 24px;
	}

	.information-cells .information-cell__link {
		width: 100%;
	}
	
	.header-contact-form {
        padding: 20px;
    }
}


@media screen and (max-width:992px) {

	.header-contact-form {
		width: 100%;
	}

	.header-contact-information {
		width: 100%;
	}

	.header-contact-body {
		display: block;
	}

	.header-contact-form p {
		font-size: 14px;
	}
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.input-login {
		font-size: 14px !important;
	}

	.header-contact-form {
		padding: 15px;
	}

	.header-contact-form .form-body {
		padding: 15px;
	}
}

@media screen and (max-width:500px) {
	.information-cells .information-cell {
		width: 100%;
	}

	.header-contact-form h3 {
		line-height: 26px !important;
	}
}