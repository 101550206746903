@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  box-sizing: border-box;
  padding:0;
  margin:0;
  font-family:poppins;
}

html {
  overflow-x: hidden;
}

body{
  font-family:poppins;
  font-size:16px;
  color:#666;
  overflow-x: hidden;
  background:#fff;
}

/* Hide the default scrollbar */
body::-webkit-scrollbar {
  width: 0;
}

/* Style for WebKit browsers (Chrome, Safari) */
body::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

body::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
}

body::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 5px; /* Rounded corners for the thumb */
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0rem !important;
}

.side-space {
  padding-left: 55px;
  padding-right:55px;
}

.pbottom-5 {
  padding-bottom: 5rem !important;
}

.ptop-5 {
  padding-top: 5rem !important;
}

.light-brwon{
  color:D46A15;
}

.light-brwon{
  color:#D46A15;
}

.red-brown{
  color:#770A00;
}

.red-colro{
  color:#E2000F;
}

.btn-black {
  background: #3C3436;
  color: #DCD6D6;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 20px;
  border-radius: 5px;
  transition: all 0.3s linear;
  text-decoration: none;
  font-family: poppins;
}

.btn-black:hover{
  background: #595455;
}

.light-brown-btn{
  background: #D46A15;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 15px;
  border-radius: 5px;
  transition: all 0.3s linear;
  text-decoration: none;
  font-family: poppins;
  text-transform:capitalize;
}

.gray-btn{
  background: #CFD8DC;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 15px;
  border-radius: 25px;
  transition: all 0.3s linear;
  text-decoration: none;
  font-family: poppins;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-select {
  padding: 0.75rem !important;
}

.form .form-control ,.form .form-select {
  border: 1px solid #d46a15;
}

.common-heading {
  font-size: 24px;
  color: #333;
  font-family: poppins;
  line-height: 30px;
  font-weight: 500;
  text-transform: capitalize;
}

.view-all {
  text-align: right;
  float: right;
  color: #333;
  font-weight: 600;
  text-decoration: none;
  font-family: 'Poppins';
  font-size: 16px;
}

.navigation-slide-style {
  position: absolute;
  top: -240px;
  width: 100%;
  z-index: 1;
}

.swiper-silder-button-prev,.swiper-silder-button-next {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #fff0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor:pointer;
  transition:all 0.3s linear;
}
.swiper-silder-button-prev:hover,.swiper-silder-button-next:hover {
  background:#c4c4c45e;
}

.swiper-button-disabled {
  cursor: not-allowed;
  border: 1px solid #fff0 !important;
}

.swiper-button-disabled .arrow-icon{
color:#777;
}
.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #12365a !important;
}

.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 12px)) !important;
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 12px)) !important;
} 


.bg-light-gray{
  background: #f9f9f9;
  }

  .heading-h1{
      font-size: 48px;
      color: #333;
      font-weight: 600;
      line-height: 50px;
      font-family: 'Poppins';
      text-transform: capitalize;
  }

  .heading-h2{
    font-size: 36px;
    color: #333;
    font-weight: 600;
    line-height: 45px;
    font-family: 'Poppins';
    text-transform: capitalize;
}

  .form-label {
    margin-bottom: 0.5rem;
    font-size: 14px;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}


.faq-box{
  width:1000px;
  margin:0 auto;
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none !important;
}

.accordion-body {
  color: #333;
  font-size: 16px;
  line-height: 27px;
  font-weight: 300;
}

@media screen and (max-width: 1600px) {
  .navigation-slide-style {
    top: -218px;
}
}


@media screen and (max-width: 1500px) {
  .navigation-slide-style {
    top: -185px;
}

.side-space {
  padding-left: 40px;
  padding-right:40px;
}
}


@media screen and (max-width: 1400px) {
  .side-space {
    padding-left: 30px;
    padding-right:30px;
  }

  .navigation-slide-style {
    top: -175px;
}
.heading-h1 {
  font-size: 40px;
  line-height: 42px;

}
}

@media screen and (max-width: 1280px) {

.navigation-slide-style {
  top: -160px;
}



.list-bottom-menu li a, .list-bottom-menu li a:hover {
  font-size: 13px !important;
}

.gray-btn {
  font-size: 13px;
  padding: 3px 12px;
}


.light-brown-btn {
  font-size: 13px;
  padding: 8px 12px;
}
}

@media screen and (max-width: 1200px) {
  .gray-btn {
    font-size: 12px;
    padding: 3px 12px;
  }
    .light-brown-btn {
    font-size: 12px;
    padding: 8px 12px;
  }

  .common-heading {
    font-size: 20px;
}



}

@media screen and (max-width: 1100px) {
  .side-space {
    padding-left: 15px;
    padding-right:15px;
  }
  .heading-h2 {
    font-size: 24px;
    line-height: 32px;
}
.nav-link, .nav-link:hover {
  font-size: 12px !important;
}
.navigation-slide-style {
  top: -150px;
}

  .navigation-slide-style {
    top: -150px;
}

.faq-box{
  width:900px;
  margin:0 auto;
}


}


@media screen and (max-width:1050px) {
  
 .navigation-slide-style {
    top: -135px;
}

.ptop-5 {
  padding-top: 3rem !important;
}
.pbottom-5 {
  padding-bottom: 3rem !important;
}

.common-heading {
  line-height: 34px;
}

.accordion-body {
  font-size: 14px;
}

}

@media screen and (max-width:1000px) {
  .faq-box{
    width:100%;
    margin:0 auto;
  }
}

@media screen and (max-width: 900px) {
  .navigation-slide-style {
    top: -120px;
}

}

@media screen and (max-width: 770px) {
  .navigation-slide-style {
    top: -110px;
}
.view-all {
  font-size: 14px;
}

.heading-h1 {
  font-size: 24px;
  line-height: 34px;
}
}


@media screen and (max-width: 580px) {
  .navigation-slide-style {
    top: -90px;
}

}

@media screen and (max-width: 450px) {
.navigation-slide-style {
  top: -70px;
}
.swiper-silder-button-prev, .swiper-silder-button-next {
  width: 35px;
  height: 35px;
}

}

@media screen and (max-width: 380px) {
  .navigation-slide-style {
      top: -55px;
  }
}