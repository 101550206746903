

.input-range {
    display: inline-block;
    height: 1em;
    position: relative;
    width: 100%;
  }
  
  .input-range__label {
    display: none;
  }
  
  .input-range__label--value {
    color: #333;
    font-family: Arial, sans-serif;
    font-size: 14px;
    position: absolute;
    top: -30px;
  }
  
  .input-range__label-container {
    display: none;
  }
  
  .input-range__track {
    background: #ccc;
    border-radius: 0.3em;
    cursor: pointer;
    display: block;
    height: 0.5em;
    position: relative;
    transition: left 0.1s;
    width: 100%;
  }
  
  .input-range__track--background {
    left: 0;
    margin-top: -0.25em;
    position: absolute;
    right: 0;
    top: 50%;
  }
  
  .input-range__track--active {
    background: #0780df;
  }
  
  .input-range__slider {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 0.5em;
    cursor: pointer;
    display: block;
    height: 1.5em;
    margin-left: -0.75em;
    margin-top: -1em;
    outline: none;
    position: absolute;
    top: 50%;
    transition: box-shadow 0.15s ease-out, left 0.15s ease-out;
    width: 1.5em;
  }
  
  .input-range__slider:active {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2);
  }
  
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2);
    outline: none;
  }
  
  .input-range--disabled .input-range__slider {
    background: #ccc;
    cursor: default;
  }
  
  .input-range--disabled .input-range__track {
    background: #eee;
    cursor: default;
  }
  
  .input-range__slider-container {
    transition: left 0.1s;
  }
  
  .input-range__slider-container:active {
    cursor: pointer;
  }
  
  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.5em;
    display: inline-block;
    font-size: 0.75em;
    position: relative;
  }
  
  .input-range__label--min {
    left: 0;
  }
  
  .input-range__label--max {
    right: 0;
  }
  
  .input-range__label-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    display: none;
  }

  .range-text p {
    margin-bottom: 0;
    font-size: 12px;
    font-family: poppins;
    color: #666;
}
  
  .input-range__label-container div {
    flex: 1;
    text-align: center;
  }
  
  .input-range__label--value {
    color: #fff;
  }
  
  