.product-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.show-result{
 font-size:14px;
 line-height:14px;
 color:#666;
 font-weight:400;
}

.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease;
  }
  
  .accordion-content.open {
    max-height: 100%;
    height: auto;
    background: #f6f6f6;
    padding: 25px;
    border-radius: 4px;
    overflow-y: scroll;
}

  .btn-gray {
    background: #CFD8DC;
    padding: 6px 10px;
    font-size: 16px;
    color: #333;
    font-weight: 500;
    border: 1px solid #ffff;
    border-radius: 5px;
    transition: all 0.5s ease;
}

.btn-gray:hover {
    background: #d46a15;
    color:#fff;
}

.adjust-icon{
    font-size:18px;
}

.page-link.active, .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color) !important;
    background-color: #d46a15 !important;
    border-color: #d46a15 !important;
}

.page-link {
    color: #d46a15 !important;
    text-decoration: none;
}

.page-link:focus {
    box-shadow: 0 0 0 0rem rgb(13 110 253 / 0%);
}

.page-link:focus {
    z-index: 3;
    color: #d46a15;
    background-color: #e9ecef00;
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(13 110 253 / 0%);
}

.filter-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 40px;
}
.filter-heading {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    font-family: poppins;
}

.filter-category {
    list-style: none;
    padding: 0;
    margin: 0;
}

.filter-category li  {
 padding:4px 0
}


.filter-category li a {
    font-size: 14px;
    color: #666;
    text-decoration: none;
    font-family: poppins;
    font-weight: 400;
    line-height: 24px;
}

.filter-category li:hover , .filter-category li a:hover  {
    color:#C75E0A;
  }

  .availability-filter label {
    font-size: 14px;
    color: #666;
    line-height: 20px;
    padding-bottom: 6px;
    font-weight: 400;
    font-family: poppins;
    text-transform: capitalize;
}

.yellow-fill {
    color: #d9a934;
}


@media screen and (max-width: 1400px) {
    .product-list {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }


  

  @media screen and (max-width: 1180px) {
    .product-list {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }


  @media screen and (max-width: 780px) {
    .product-list {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .show-result {
        font-size: 12px;
    }
  }


  @media screen and (max-width: 550px) {
    .product-list {
        grid-template-columns: 1fr 1fr;
    }
  }