
.checkout-text p {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    line-height: 28px;
    margin: 0;
    font-family: 'Poppins';
}

.checkout-content {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    grid-column-gap:40px;
}

.ancher-text {
    color: rgb(212 106 21);
}

.pickuppoin-address p {
    font-size: 16px;
    line-height: 24px;
    color: #333;
    font-family: poppins;
    font-weight: 400;
}

@media only screen and (max-width:1025px){

    .checkout-content {
        grid-template-columns: 1fr .6fr;
        grid-column-gap: 10px;
    }
}

@media only screen and (max-width:992px){

    .checkout-content {
        grid-template-columns: 1fr;
        grid-column-gap: 10px;
    }  

    .checkout-text p {
        font-size: 14px;
        line-height: 26px;
    }
    .pickuppoin-address p {
        font-size: 14px
    }
}


@media only screen and (max-width:767.5px){
.checkout-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
}

.w-sm-100 {
    width: 100% !important;
}

}