.video-section.position-relative.overflow-hidden {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 650px;
}

.overlay_video:after {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    left: 0;
    top: 0;
    content: "";
    opacity: .6;
}

.video-content-parent {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    left: 0;
    justify-content: center;
    color: #fff;
    z-index: 9;
    height: 100%;
    width: 100%;
    text-align: center;
}

.h2-head {
    font-size: 72px;
    line-height: 50px;
    color: #d46a15;
    font-weight: bold;
    font-family: 'Poppins';
    text-transform: uppercase;
}

.h1-head {
    font-size: 85px;
    line-height: 70px;
    color: #3c3436;
    font-weight: bold;
    font-family: 'Poppins';
    text-transform: uppercase;
}

.para-head {
    font-size: 34px;
    line-height: 30px;
    color: #3c3436;
    font-weight: 500;
    font-family: 'Poppins';
    text-transform: uppercase;
}

@media only screen and (max-width: 1500px) {
    .h1-head {
        font-size: 75px;
        line-height: 70px;
    }

    .h2-head {
        font-size: 65px;
    }
}

@media only screen and (max-width: 1370px) {
    .h1-head {
        font-size: 65px;
        line-height: 70px;
    }
    .h2-head {
        font-size: 60px;
    }
}


@media only screen and (max-width: 1200px) {
    .h1-head {
        font-size: 58px;
        line-height: 70px;
    }
    .h2-head {
        font-size: 55px;
        line-height: 36px;
    }
}

@media only screen and (max-width: 1050px) {

.para-head {
 font-size: 28px;
 line-height: 24px;
}
.video-section.position-relative.overflow-hidden {
    height: auto;
} 
}

@media only screen and (max-width: 800px) {

.h1-head {
    font-size: 44px;
    line-height: 50px;
}

.h2-head {
    font-size: 40px;
    line-height: 30px;
}

.para-head {
    font-size: 24px;
}

}
@media only screen and (max-width: 600px) {
.h1-head {
    font-size: 36px;
    line-height: 36px;
}

.h2-head {
    font-size: 30px;
    line-height: 20px;
}

.para-head {
    font-size: 18px;
}

.about-info h4 {
    font-size: 18px;
    line-height: 24px;
}

}

@media only screen and (max-width: 500px) {
    .h1-head {
        font-size: 25px;
        line-height: 26px;
    }
    
    .h2-head {
        font-size: 30px;
        line-height: 20px;
    }
    
    .para-head {
        font-size: 16px;
    }
    
    }