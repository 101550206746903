.blog-details-page {
    background-color: #cfd8dc6e;
    padding: 30px;
    -webkit-border-radius: 8px 8px 0 0;
    border-radius: 4px;
    margin: 15px auto 0;
    position: relative;
    overflow: hidden;
}

.blog-date {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 8px;
    color: #333;
    margin: 0;
    line-height: 30px;
}

.blog-heading {
    text-align: left;
    color: #010101;
    text-transform: uppercase;
    font-family: poppins;
    font-weight: 400;
    font-size: 24px;
    margin-bottom:15px;
}

.bog-description p {
    color: #333;
    font-weight: 300;
    word-spacing: 2px;
    text-align: left;
    line-height: 30px;
    font-size: 16px;
}

.blog-recent {
    display: flex;
    border: 1px solid #bfbebe;
    padding: 10px;
    border-radius: 3px;
    background: #fff;
    gap: 10px;
}

.blog-recent h4{
    color: #333;
    font-weight: 400;
    text-align: left;
    line-height: 22px;
    font-size: 16px;
}

.recent-blog-img {
    width: 160px;
    height: 70px;
}

.bog-description1 {
    color: #333;
    font-weight: 300;
    word-spacing: 2px;
    text-align: left;
    line-height: 22px;
    font-size: 14px;
}

.recent-blog-img img {
    object-fit: cover;
    height: inherit;
    object-position: left;
    border-radius: 2px;
    width: inherit;
}

@media screen and (max-width: 1025px) {

.blog-heading {
    text-align: left;
    color: #010101;
    text-transform: uppercase;
    font-family: poppins;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 15px;
    line-height: 28px;
}

.bog-description p {
    line-height: 26px;
    font-size: 14px;
}

.blog-details-page {
    padding: 15px;
}

.blog-recent h4 {
    line-height: 22px;
    font-size: 14px;
}
}