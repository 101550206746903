
.mobile-stickybar {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
}
.mobile-stickybar.active {
    visibility: visible;
    opacity: 1;
}

.mobile-sticky-wrp {
    box-shadow: 0 5px 30px #00000042;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 0;
    padding: 8px 15px;
    gap: 15px;
    transition: all ease-in-out .5s;
    list-style:none;
}

.mobile-sticky-wrp svg {
    height: 20px;
    width: 20px;
    margin: 0 auto 5px;
}

.mobile-sticky-wrp span {
    display: block;
    line-height: 1;
}

.mobile-sticky-wrp li {
    line-height: 1;
    text-align: center;
}

.mobile-sticky-wrp span {
    display: block;
    line-height: 1;
    font-size: 14px;
    text-transform: capitalize;
}

.mobile-sticky-wrp a {
    text-decoration: none;
    color: #333;
}

@media screen and (min-width: 768px) {
    .mobile-sticky-menu {
        display: none !important;
    }
}

@media screen and (max-width: 768px) {
    .mobile-sticky-menu {
        display: block;
    }
}

@media  (max-width:500px){
    .mobile-sticky-wrp span {
        font-size: 13px;
    }

    .mobile-sticky-wrp svg {
        height: 20px;
        width: 20px;
        margin: 0 auto 5px;
    }
}