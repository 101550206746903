/* -------Login----- */
/* .home {
    font-family: "poppins", sans-serif;
    height: 100%;
    width: 100%;
    padding: 0;
    background-color: #4c9396;
    background-image: url(../images/fresh-rituals-bg.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
    position: relative;
} */
.login-container ::placeholder {
  color: #cfd8dc !important;
  opacity: 1;
}
.fullscreen {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #cfd8dc7a;
}

.login-container {
  width: 100%;
  width: 750px;
  padding: 40px;
  background-color: #3c3436;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.login-container h2 {
  font-size: 36px;
  color: #f5f5f5;
  font-family: "Poppins";
  font-weight: 400;
  text-align: center;
}

.table > :not(caption) > * > * {
  color: var(
    --bs-table-color-state,
    var(--bs-table-color-type, #242424)
  ) !important;
}
.login-container p {
  font-size: 16px;
  color: #f5f5f5;
  font-family: "Poppins";
  font-weight: 300;
}

.password-icon {
  cursor: pointer;
  position: absolute;
  top: 45%;
  right: 12px;
  transform: translateY(-50%);
  color: #f5f5f5;
}

.login-container .form-control {
  border: 1px solid #f5f5f54d;
  border-radius: 0;
  background: #fff0;
  color: #cfd8dc !important;
  border-radius: 4px;
}

.login-container .input-group-text {
  color: #cfd8dc !important;
  background-color: #d46a15 !important;
  font-size: 20px;
  border: var(--bs-border-width) solid #f5f5f54d;
  font-weight: 500;
}

.custom-file-input::-webkit-file-upload-button,
.custom-file-input:hover::-webkit-file-upload-button {
  background: #5c5a5a00 !important;
  color: #cfd8dc !important;
}

.custom-date-input input[type="date"]::-webkit-calendar-picker-indicator {
  color: #fff !important;
}

.login-container .form-select {
  color: #120f10 !important;
  background-color: #fff0 !important;
  border: var(--bs-border-width) solid #3c343669 !important;
  filter: invert(1) !important;
  font-weight: 500;
}

.login-container .form-control:focus {
  color: #f5f5f5;
  background-color: #ffffff03;
  border-color: #f5f5f5;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 0%);
}

.custom-dropdown::after {
  content: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3e%3cpath fill="none" stroke="%23343a40" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m2 5 6 6 6-6" /%3e%3c/svg%3e');
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.form-lable .form-label {
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 7px;
  opacity: 0.5;
  pointer-events: none;
  transition: transform 0.2s, font-size 0.2s, color 0.2s;
  color: #f5f5f5;
  font-weight: 300;
  padding: 7px 7px 7px 0px;
  letter-spacing: 1px;
  font-family: "Poppins";
}

.form-group.focused .form-label {
  transform: translateY(-90%);
  font-size: 12px;
  color: #f5f5f5;
  opacity: 1;
  padding-top: 10px !important;
}

.form-group {
  margin-bottom: 1.5rem;
}

.or {
  color: #fff;
  border-bottom: 1px solid #f5f5f55e;
}

.or-style {
  width: 40px;
  background: #3c3436;
  padding: 5px 20px;
  position: relative;
  top: 10px;
  font-family: "Poppins";
  font-size: 14px;
}

.login-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-top: 15px;
  background: #c75e0a !important;
  color: #fff !important;
  font-family: "Poppins";
  font-weight: 600;
  transition: all 0.3s linear;
}

.login-container option {
  color: #333;
}

.login-button:hover {
  background: #91c244;
  color: #fff;
}

.google-login-btn img {
  height: 20px;
  margin-right: 15px;
  padding-top: 1px;
  width: 20px;
}

.google-login-btn,
.google-login-btn:hover {
  background: #fff !important;
  width: 100%;
  font-family: "Poppins";
  font-weight: 500 !important;
  color: #333;
  text-align: center;
  padding: 8px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.login-para a,
.login-para p {
  font-size: 16px;
  color: #f5f5f5;
  font-family: "Poppins";
  line-height: 20px;
}

.them-color-text {
  color: #e47015 !important;
  font-weight: 500;
  margin-left: 0px;
  transition: all 0.3s linear;
  text-transform: capitalize;
}

.them-color-text:hover {
  color: #cfd8dc !important;
}

.width-50 {
  width: 50%;
}

.login-container h2 {
  font-weight: 400;
  font-size: 36px;
  line-height: 40px;
  color: #fff;
  font-family: "Poppins";
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
/*---------------- Media Query---Responsive Device----------------- */
@media only screen and (max-width: 800px) {
  .login-container {
    padding: 20px;
    min-width: 100%;
    width: 650px;
    border: 1px solid #ffffff45;
    overflow-x: hidden;
  }

  .login-container h2 {
    font-size: 24px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 700px) {
  .login-container {
    padding: 20px;
    min-width: 100%;
    border: 1px solid #ffffff45;
    overflow-x: hidden;
  }

  .login-para a,
  .login-para p {
    font-size: 14px;
    line-height: 24px;
  }
  .them-color-text {
    margin-left: 0px;
  }

  .login-container {
    padding: 20px;
    min-width: 100%;
    width: 100%;
    border: 1px solid #ffffff45;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 576px) {
  .w-sm-100 {
    width: 100%;
  }
}
