
.media-card-logo {
    border: 6px outset #882920;
    transition: all .5s;
    cursor: pointer;
    transition:all 0.3s linear;
    overflow:hidden;
    height:120px;

}

.media-card-logo img {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 15px;
    border: 1px dotted #c6c4c4;
    filter: grayscale(1);
    transition: all .5s;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
}

.media-card-logo:hover img {
    filter: grayscale(0);
}

