
  

  .prod_cart {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #b4b4b42e;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:all 0.3s linear;
    color:#000;
}

.prod_cart:hover {
    background: #d46a15 ;
    color:#fff;
}

.prod_cart svg { 
    font-size:18px;
}
.coupon-list{
  width: 100%;
  background: #fff;
  border-radius: 4px;
}

.coupon-list p {
  font-size: 12px !important;
  font-weight: 600 !important;
  font-family: 'Poppins';
  color: #333 !important;
}
.coupon-card-width {
  width: 140px;
  border: 1px dashed #D46A15;
  border-radius: 4px;
  padding: 6px;
}

.coupon-card-width p {
  width: inherit;
}
.sidebar-cart p {
  font-size: 12px;
  font-family: 'Poppins';
  color: #666;
  font-weight: 400;
}


.mini-cart-item:not(:last-of-type) {
  margin-bottom: 20px;
}

.mini-cart-item {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ddd;
  background: #fff;
}

.mini-cart-image {
  width: 80px;
}

.mini-cart-image a {
  display: block;
  padding-top: 138%;
  border-radius: 12px;
  position: relative;
}

.mini-cart-image img {
  top: 0;
  object-fit: scale-down;
  object-position: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0;
}

.mini-cart-details {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  padding-left: 15px;
}

.mini-cart-title ,.cart-variable p {
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #666;
  text-decoration: none;
  line-height: 24px;
}

.mini-cart-qty{
width:150px;
}

.mini-cart-qty .quantity-control button {
  padding: 1px 15px;
}
.price {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1;
  font-weight: 500;
  font-size: 15px;
  color: #333;
}

.pvarprice {
  margin-top: 12px;
}

.cart-remove-button {
  display: flex;
}
.cart-payment{
    display: flex;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
    margin-top: 60px;
    position: absolute;
    bottom: 0;
    background: #ffffff;
    padding: 10px 0;
    border-top: 1px solid #e1e1e1;
}

.offcanvas-body {
  overflow: hidden;
}

.cart-body {
  padding-right:6px;
  height: 600px; 
  overflow-y: auto; 
  overflow-x:hidden;
}



.coupon-list{
  overflow-x: auto; 
  overflow-y: hidden; 
}
.coupon-list::-webkit-scrollbar{
  height: 4px !important; 
}

.coupon-list::-webkit-scrollbar-thumb:hover {
  background-color: #b0b0b0 !important; /* Explicit hover color */
}

.cart-body::-webkit-scrollbar {
  width: 6px !important; 
}

.cart-body::-webkit-scrollbar-track  , .coupon-list::-webkit-scrollbar-track{
  background-color: #f1f1f1; 
}

.cart-body::-webkit-scrollbar-thumb , .coupon-list::-webkit-scrollbar-thumb{
  background-color: #CFD8DC !important; 
  border-radius: 5px; 
}


.cart-body::-webkit-scrollbar-thumb:hover {
  background-color: #b0b0b0 !important; /* Explicit hover color */
}
