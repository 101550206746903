
.quantity-container {
    text-align: center;
    margin-top: 10px;
  }
  

  .quantity-control {
    display: flex;
    width: 100%;
    border: 1px solid #ccc;
    align-items: center;
    border-radius: 2px;
}
  
  .quantity-control button {
    background-color: #e8edef;
    border: 0;
    color: #666;
    padding: 6px 25px;
    cursor: poi nter;
    font-size: 24px;
  }
  
  .quantity-control .qty {
    width: 100%;
    font-size: 16px;
    color: #666;
    font-family: 'Poppins';
    margin: 0 10px;
}
  .quantity-control button:hover {
    background-color: #e5e5e5;
  }
  
