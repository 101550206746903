header {
  transition: all 0.3s linear;
  box-shadow: 2px 0 12px #4947476e;

}

header.sticky {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background: #fff;
}

.navbar-brand img {
  max-width: 160px;
}

.nav-item {
  position: relative;
  transition: all 200ms linear;
}

.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  display: block;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
}
.nav-item.show .dropdown-menu {
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0px, 0);
  background-color: rgb(255 255 255 0.9);
  -webkit-animation-name: blur(9px);
  animation-name: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  backdrop-filter: blur(9px);
  border-radius: 4px;
}
.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}
.dropdown-toggle::after {
  display: none;
}
.nav-item:hover > .dropdown-menu {
  display: block;
}
.dropdown-item {
  padding: 3px 0px;
  color: #33393d;
  border-radius: 2px;
  transition: all 200ms linear;
  font-family: "aeonikt-light";
  font-weight: 600;
  white-space: normal !important;
  font-size: 14px;
  line-height: 24px;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #020202 !important;
  background-color: rgba(255, 255, 255, 0);
}

.navbar-expand-md .navbar-nav .dropdown-menu {
  position: absolute;
  top: 36px !important;
  left: 50%;
  transform: translateX(-50%);
}

.mega-box {
  display: flex;
  width: auto;
  flex-wrap: wrap;
  width: 1000px;
}
.mega-box-category {
  width: 300px;
}

.login-user-icon {
  width: 35px;
  height: 35px;
  background: #3b3436;
  color: #a7a7a7;
  border-radius: 50%;
  padding: 4px;
  border: 2px solid #a7a7a7;
}

.cart-no {
  position: relative;
  top: 7px;
  right: 27px;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
}

.cart-tot-text {
  font-size: 13px;
  font-weight: 400;
  color: #000;
  text-decoration: none;
  font-family: poppins;
}

.map-icon {
  color: #828282;
  font-size: 24px;
}

.w-custom {
  width: 236px;
}

ul.catgory-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mega-menu {
  list-style: none;
  padding: 5px 20px;
  margin: 0;
  flex-basis: calc(25% - 10px);
  border-right: 1px solid #e0e0e0;
}

.line-height-0 {
  line-height: 0;
}
.fa-14 {
  font-size: 14px !important;
  font-weight: 400;
}
.swiper-wrapper {
  padding: 0 !important;
}

.mega-menu:last-child {
  border-right: none;
}

.inner-menu {
  list-style: none;
  display: block;
  position: relative;
  float: left;
  max-width: 100%;
  margin: 0;
  padding: 0px;
}

.inner-menu li a,
.inner-menu li a:hover {
  color: #333 !important;
  font-weight: 400;
  font-family: poppins;
  text-decoration: none;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 20px;
  padding: 6px 3px !important;
}

.inner-menu li {
  padding: 3px 2px;
  transition: all 0.3s linear;
  border-radius: 2px;
}

.inner-menu li:hover {
  background: #cfd8dc;
}

.menu-title {
  font-size: 14px;
  text-decoration: none;
  color: #333;
  font-family: "poppins";
  text-transform: uppercase;
  font-weight: 600;
}

.dropdown-toggle::after {
  font-size: 18px;
}

.cart img {
  width: 35px;
}
.border-radius-50 {
  border-radius: 50px;
}

.list-menu {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
}

.list-bottom-menu {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}

/* .sticky .bottom-header {
  display: none !important;
} */

.display-flex {
  display: flex;
}

.nav-item {
  margin: 0 35px 0 0;
}

.right-border-black {
  border-right: 1px solid #000;
}

.nav-link,
.nav-link:hover {
  color: #000 !important;
  font-weight: 400 !important;
  font-family: poppins;
  text-decoration: none;
  font-size: 13px !important;
  text-transform: capitalize;
}

.list-bottom-menu li a,
.list-bottom-menu li a:hover {
  font-size: 14px !important;
}

.nav-link:hover {
  color: #d46a15 !important;
}

.gray-color {
  color: #5f5f5f;
}

.bar-icon svg {
  background: #303037;
  color: #cac9c9;
  width: 28px;
  height: 28px;
  padding: 7px;
  border-radius: 50%;
  margin-right: 8px;
}

.vertical-divider {
  border-right: 1px solid rgb(168, 168, 168);
  display: inline-block;
  height: 36px;
  margin: 0 25px;
  vertical-align: middle;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.cart_login ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width:100%;
}
.mega-box-signin {
  width: 250px;
}

.mega-box-1 {
  display: flex;
}

.mega-menu-1 {
  list-style: none;
  padding: 5px 15px;
  margin: 0;
  border-right: 1px solid #c1c1c163;
}

.mega-menu-1:last-child {
  border-right: none;
}

.dropdown-menu {
  transform: translateX(-50%);
}

.nav-item:hover:after,
.menu-item:hover:after {
  bottom: -16px;
  opacity: 1;
}

.text-color {
  color: #5f5f5f !important;
}

.container-side-space {
  padding: 0 70px;
}

.hr-line {
  border-top: 1px solid #cfd8dc;
  margin: 0.5rem 0;
}

.fa-600 {
  font-weight: 600;
}

.header-text {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #e9ecef !important;
  outline: 0;
  box-shadow: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ffffff !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #5f5f5f !important;
  text-decoration: none;
  background-color: #ffffff00 !important;
}

.navbar-expand-md .navbar-nav .nav-link {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-position {
  transform: translate(-50%, 5px) !important;
  left: 50%;
}

.gray-bg {
  background: #cfd8dc !important;
}

.navigation-wrap {
  /* border-bottom: 1px solid #cfd8dc7a; */
}

.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.width-100 {
  width: 100%;
}
.login-btn p {
  color: #666;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

.topmenu-padding {
  padding: 0 25px 0 0 !important;
}

@media screen and (max-width: 1600px) {
.w-custom {
  width: 200px;
}
.category-menu {
  margin-left: 1.7rem;
}
}

@media screen and (max-width: 1370px) {
  .w-custom {
    width: 202px;
  }
  }

@media screen and (min-width: 1300px) {
  .dropdown-item {
    text-wrap: nowrap !important;
  }
  
}

@media screen and (max-width: 1300px) {
.topmenu-padding {
  padding: 0 15px 0 0 !important;
}
.w-custom {
  width: 205px;
}
}

@media screen and (max-width: 1260px) {
  .navbar-brand img {
    max-width: 130px;
}
}

@media screen and (max-width: 1260px) {
  .d-none-tablet{
    display:none;
  }
  .w-tablet-100{
    width:100% !important;
  }
  .category-menu {
    margin-left: 0.7rem;
}
}

@media screen and (max-width: 1200px) {
.w-60-tablet{
  width:60% !important
}

.list-bottom-menu li a, .list-bottom-menu li a:hover {
  font-size: 12px !important;
}

}

@media screen and (max-width: 1100px) {
.topmenu-padding {
  padding: 0 12px 0 0 !important;
}
.w-medium-80 {
  width: 62%;
}
}

@media screen and (max-width: 1050px) {
.category-menu {
  margin-left: -0.3rem;
}
}


@media screen and (max-width: 992px) {
.w-custom {
  width: 165px;
}

.sticky .bottom-header {
  display: flex !important;
}

}

@media screen and (min-width: 1100px) {
.w-medium-80{
width:80%;
}
}

@media screen and (max-width:770px) {


  .w-medium-80 {
    width: 100%;
}
.category-menu {
  margin-left: 1.7rem;
}
}

@media screen and (max-width:600px) {
.navbar-brand img {
  max-width: 120px;
}
}

@media screen and (max-width:576px) {
  .cartimg {
    border-left: 1px solid #e1e1e1;
    padding-left: 12px;
  }

  .dropdown-position {
    transform: translate(-60%, 5px) !important;
    left: 0%;
}

  .w-custom {
    width: 55px;
}
.cartimg {
  border-left: 1px solid #e1e1e1;
  padding-left: 12px;
  text-align: right;
  float: right;
}

.cart-no {
  position: relative;
  top: 16px;
  right: -40px;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
}
.bar-icon svg {
  width: 30px;
  height: 30px;
}
}