.cate-item {
    position: relative;
    overflow: hidden;
    margin: 0;
    height: 480px;
  }

.cate-item img {
  object-fit: cover;
  object-position: center;
  height:inherit; 
  width:100%;
  }
.cate-bottom-overlay {
  top:70%;
}

.cate-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2a73bd75;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: .5s ease;
}

.cate-item:hover .cate-bottom-overlay {
  top: 0;
}

.cate-item:hover .cate-overlay {
  bottom: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to top, #2a73bdc9, #fff0)
}

.categ-content {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-family: Poppins;
  z-index: 3;
}

.categ-content h1 {
  font-size: 20px;
  letter-spacing: 2px;
  line-height: 30px;
  color: #000000;
  font-family: "poppins";
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 18px;
}