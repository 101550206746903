.outer-box {
    border: 1px solid #d8dfe2;
    padding: 6px;
}

.inner-box {
    background: #cfd8dcd1;
    padding:25px;
}

.checkout-heading {
    color: #5f5f5f;
    font-size: 24px;
    line-height: 24px;
    text-transform: capitalize;
    font-family: 'Poppins';
    margin: 0;
    font-weight: 500;
}

.inner-box .form-control {
    border: var(--bs-border-width) solid #cfd8dc !important;
    transition:all 0.3s linear
}

.inner-box .form-control:focus  {
    border-color: #d46a15 !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}
