
.gallery-list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

@media only screen and (max-width:1025px) {
    .gallery-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width:600px) {
    .gallery-list {
        grid-template-columns: 1fr;
    }
}