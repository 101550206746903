.store-card {
    border: 6px outset #882920;
    transition: all .5s;
    cursor: pointer;
    transition:all 0.3s linear;
    overflow:hidden;
}
.store-img {
    width: 100px;
    margin: 0 auto;
}

.store-card img {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 15px;
    border: 1px dotted #c6c4c4;
    filter: grayscale(1);
    transition: all .5s;
    max-width: 100%;
}

.store-card:hover img {
    filter: grayscale(0);
}

.store-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 25px;
}

.store-info h3 {
    font-size: 18px;
    color: #333;
    line-height: 24px;
    font-weight: 500;
    font-family: 'Poppins';
}

.sold-details p {
    font-size: 16px;
    color: #333;
    font-weight: 300;
    margin-bottom: 5px;
}

.sold-details p a{
    font-size: 16px;
    color: #333;
    font-weight: 300;
    margin-bottom: 5px;
    text-decoration:none
}


.sold-details {
    padding: 1rem;
}

.store-card:hover .store-overlay {
    bottom: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to top, #8829201f 30%, #fff0 70%)
}

.store-card:hover .store-bottom-overlay {
    top: 0;
}
.store-bottom-overlay {
    top: 0%;
}
.store-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    transition: .5s ease;
}

@media only screen and (max-width: 1350px) {
    .store-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }
}

@media only screen and (max-width: 1000px) {
    .store-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 25px;
    }
}

@media only screen and (max-width: 576px) {
    .store-list {
        display: grid;
        grid-template-columns: 1fr ;
        gap: 25px;
    }
}