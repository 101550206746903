
  .back-btn{
    padding: 10px 5px;
    text-decoration: none;
    font-size: 18px !important;
    color: #333;
    display: flex;
    transition: 0.3s;
    width: 100%;
    font-family: "Poppins";
    border: none;
    justify-content: start !important;
    text-transform: capitalize;
    align-items: center !important;
    gap: 20px !important;
    font-weight: 600 !important;
    background: transparent;
  }

    .profile-icon img {
      border: 2px solid #7f8385;
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
  
    #hmenu-customer-profile {
        display: flex;
        gap: 15px;
        align-items: center;
        padding: 20px;
        background: #3c3436;
    }

    #hmenu-customer-name {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        font-family: 'Poppins';
    }

    .menu a.sidebar-link {
        color: #000 !important;
        font-family: poppins;
        text-decoration: none;
        font-size: 13px !important;
        text-transform: capitalize;
        text-decoration: none;
        font-weight: 600;
    }
    .sideoverlay.open {
      width: 70%;
    }
  
    .for-desktop-device {
      display: none;
    }
  
    /* .sticky .navbar-brand {
      display: none;
    }
   */
    .sticky .scroll-logo {
      width: 180px;
    }
    /* Navbar.css */
  
    .overlay-navbar {
      height: 100%;
      width: 0;
      position: fixed;
      z-index: 9999;
      top: 0;
      left: 0;
      background-color: rgb(255 255 255);
      overflow-x: hidden;
      transition: 0.5s;
      backdrop-filter: blur(12px);
    }
    .dropdown {
        transition: all 0.5s  linear;
    }
    .overlay-navbar.open {
      width: 20%;
    }
  
    /* .closebtn {
      position: absolute;
      top: 20px;
      right: 10px;
      font-size: 30px;
      background: #ffff;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: #f00;
    }
   */
    /* .overlay-navbar-content {
      position: relative;
      top: 0%;
      width: 100%;
      text-align: center;
    }
   */
    .overlay-navbar a {
      padding: 10px 5px;
      text-decoration: none;
      font-size: 16px;
      color: #333;
      display: flex;
      transition:all 0.3s linear;
      width: 100%;
      justify-content: space-between;
      font-family: "Poppins";
      border-bottom: 1px solid #f3f3f3;
      text-transform: capitalize;
      border-radius:2px;
    }


    .overlay-navbar a:hover,
    .overlay-navbar a:focus {
      color: #333;
      background:#CFD8DC;
    }
  
    .overlay-navbar .closebtn, .overlay-navbar .closebtn:hover {
        position: absolute;
        top: 20px;
        right: 15px;
        font-size: 30px;
        background: #fff0;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: rgb(255 255 255);
        border: 2px solid rgb(189, 189, 189);
    }
  
    .overlay-navbar-content ul {
        list-style: none;
        padding: 0;
    }

    .overlay-navbar-content ul li:hover {
      transition:all 0.3s linear;
    }

    .overlay-navbar-content {
      position: relative;
      width: 100%;
      text-align: left;
      padding: 20px;
    }
  
    .dropdown.open .dropdown-content {
      display: block;
    }
  
    .dropdown-content {
      display: none;
      position: relative;
      z-index: 1;
    }
  
    .dropdown-content a {
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      color: #333;
      transition: background-color 0.3s;
      border-bottom: 1px solid #dfdedea3;
    }
  
    .dropdown.open .dropdown-content {
      display: block;
    }
    .dropdown-content a:hover {
      background-color: #ddd;
    }
  
    .dropdown-content i {
      margin-left: auto;
      font-size: 18px;
    }

    @media screen and (max-width: 1300px) {
    .overlay-navbar.open {
      width: 25%;
    }
    .overlay-navbar a {
      font-size: 14px;
  }
    }

    @media screen and (max-width: 992px) {
     
      .overlay-navbar.open {
        width: 40% !important;
      }
      }
      

      @media screen and (max-width: 768px) {
        .overlay-navbar.open {
          width: 90% !important;
        }
        }
        