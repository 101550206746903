.discount-code h3 {
    font-size: 24px;
    color: #333;
    font-weight: 500;
    line-height: 30px;
    font-family: 'Poppins';
    margin-bottom: 5px;
}

.discount-code p {
    font-size: 16px;
    margin: 0;
    color: #666;
    line-height: 24px;
    font-family: 'Poppins';
    font-weight: 400;
}

b, strong {
    font-weight: 600 !important;
}

.coupon-row {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}

.coupon-width {
    width: calc(100% - 65%);
}

@media screen and (max-width:1200px) {

.coupon-width {
    width: calc(100% - 50%);
}

.discount-code h3 {
    font-size: 20px;
}

}

@media screen and (max-width:576px) {

.coupon-row {
    display: block;
}

.coupon-width {
    width: calc(100% - 40%);
    margin-top: 1rem;
    text-align: center;
    margin-left: auto;
}
}