.post-review {
    font-size: 14px;
    background: #dd894d;
    padding: 8px 15px;
    color: #fff;
    border-radius: 3px;
    position: relative;
    top: 12px;
    font-weight: 550;
    text-decoration: none;
}


/*rating*/


/*rating*/

.star-rating {
    display: flex;
    flex-direction: row-reverse;
    font-size: 1.5em;
    justify-content: space-around;
    padding: 0;
    text-align: center;
    width: 7.5em;
}
  .rate-box h4 {
    font-size: 20px;
    color: #5f5f5f;
    line-height: 24px;
    text-transform: capitalize;
    font-family: 'Poppins';
}
  
  .star-rating input {
    display:none;
  }
  
  .star-rating label {
    color:#ccc;
    cursor:pointer;
    font-size:34px !important;
  }
  
  .star-rating :checked ~ label {
    color:#f90 !important;
  }
  
  .star-rating label:hover,
  .star-rating label:hover ~ label {
    color:#fc0;
  }
  
  a.post-review {
      font-size: 14px;
      background: #dd894d;
      padding: 8px 15px;
      color: #fff;
      border-radius: 3px;
      position: relative;
      top: 12px;
      font-weight: 550;
  }
  
  .rate {
     height: 46px;
     text-align: left;
     /*display: flex;*/
  }
  .rate:not(:checked) > input {
      position:absolute;
      top:-9999px;
  }
  .rate:not(:checked) > label {
      float:left;
      width:1em;
      overflow:hidden;
      white-space:nowrap;
      cursor:pointer;
      font-size:30px !important;
      color:#ccc;
  }
  .rate:not(:checked) > label:before {
      content: '★ ';
  }
  .rate > input:checked ~ label {
      color: #ffc700;    
  }
  .rate:not(:checked) > label:hover,
  .rate:not(:checked) > label:hover ~ label {
      color: #deb217;  
  }
  .rate > input:checked + label:hover,
  .rate > input:checked + label:hover ~ label,
  .rate > input:checked ~ label:hover,
  .rate > input:checked ~ label:hover ~ label,
  .rate > label:hover ~ input:checked ~ label {
      color: #c59b08;
  }

  .modal-heading{
    color:#5f5f5f;
    font-family: poppins;
    line-height: 24px;
  }

  .customer-review {
    font-size: 18px;
    text-transform: uppercase;
    color: #333;
    padding: 30px 0;
    font-family: 'Poppins';
}

.rating-inner p {
    color: #5f5f5f;
    font-weight: 400;
    font-size: 16px;
}

.customer-rating {
    background: #003a07;
    color: #fff;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 14px;
}

.review-content h3{
    font-size: 18px;
    text-transform: uppercase;
    color: #5f5f5f;
}

.fn {
    font-size: 16px !important ;
    line-height: 1.5rem  !important ;
    color: #333 !important;
    display: block;
    font-family:poppins;
    font-style: normal;
    font-weight: 500  !important ;
}
.comment-meta {
    font-size: 0.875rem;
    line-height: 1rem;
    margin-bottom: 0.75rem;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    align-items:center;
    flex-wrap: wrap;
    justify-content: flex-start;
    background: #355d37;
    padding: 4px;
    width: fit-content;
    border-radius: 4px;
}

.comment-meta p {
   color:#fff ;
   font-size:14px;
}
.comment-author-avatar img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
.tab-content ul li {
    color: #666;
    font-size: 16px;
    color: #666;
    line-height: 28px;
    font-family: 'Poppins';
    font-weight: 300;
    text-transform: capitalize;
  }

  .form-control {
    padding: 0.75rem 0.75rem !important;
}

.comment-body {
    display: flex;
    gap: 22px;
    margin-top: 20px;
}
.mad-rating {
    font-size: 1.3125rem;
    line-height: 1;
    white-space: nowrap;
}

.comments-list {
    list-style: none;
    padding: 0;
}

.rating-icon {
    font-size: 18px;
    color: #FFC107;
    margin-left: 10px;
}
@media screen and (max-width: 1025px) {
.tab-content p {
    font-size: 14px;
    line-height: 22px;
}
}
@media screen and (max-width: 368px) {

    .rating-icon {
    margin-right: 10px;
    margin-left:0;
}
}