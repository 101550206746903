
.cart-wrapper {
    display: grid;
    grid-template-columns: 1fr 0.3fr;
}
.view-cart-product {
    display: grid;
    grid-template-columns: .13fr 1fr;
    grid-column-gap: 20px;
    border-bottom: 1px solid #e7e7e7;
    padding: 12px;
}

.cart-prod-img {
  width: 130px;
}

.cart-prod-img img {
    max-width: 100%;
    border: 1px solid #33333321;
    border-radius: 4px;
}
.cart-prod-name {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.cart-rod-detail h3 {
    font-family: 'Poppins';
    font-size: 22px;
    margin-bottom: 0.2rem;
    line-height: 30px;
    color: #5f5f5f;
}

.cart-rod-detail p {
    font-family: 'Poppins';
    font-size: 15px;
    line-height: 30px;
    color: #5f5f5f;
    font-weight: 400;
    margin: 0;
}

.prod-price p {
    margin: 0;
    color: #333;
    font-size: 20px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 30px;
}

.link-text, .link-text:hover{
    color: #43b0e3;
    font-size: 16px;
    font-weight: 500;
    font-family: poppins;
}

.custom-dropdown-style.show {
    border: 1px solid #bbb !important;
    box-shadow: 0 5px 10px 0 rgb(138 155 165 / 0%) !important;
}

.dropdown-menu.show {
    display: block;
}

.transparent-btn, .transparent-btn:hover {
    background: transparent !important;
    color: #d46a15 !important;
    outline: none !important;
    border: none !important;
    text-decoration: underline !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.custom-dropdown-style a {
    font-family: 'Poppins';
    font-size: 15px;
    line-height: 22px;
    color: #5f5f5f;
    font-weight: 400;
    background: #f8f9fa;
}

.custom-dropdown-style:before {
    background-image: url(../../assets/dropdown-arrow.svg);
    background-position: 0;
    background-repeat: no-repeat;
    bottom: auto;
    content: "";
    display: flex !important;
    height: 8px !important;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover !important;
    position: absolute !important;
    top: -8px !important;
    width: 16px !important;
    left: 10% !important;
    transform: translateX(-50%) !important;
}

.cart-info-btn {
    border: 0;
    background: transparent;
    text-decoration: underline;
    color: #d46a15;
    font-size: 15px;
    font-weight: 400;
}

.cart-details-left h3 {
    font-family: 'Poppins';
    font-size: 22px;
    margin: 0;
    line-height: 30px;
    color: #333;
    font-weight: 400;
}



.cart-wrapper {
    display: grid;
    grid-template-columns: 1fr 0.3fr;
    grid-column-gap: 70px;
}

.view-cart {
    background: #f8f9fa;
    padding: 20px;
}

.Procced-cart {
    background: #f8f9fa;
    padding: 20px;
    height: fit-content;
}

.sub-total span {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    font-family: 'Poppins';
    line-height: 24px;
}
.fa-15{
font-size: 14px !important;
font-weight: 400 !important;
}

.dropdown span {
    color: #333;
    font-family: 'Poppins';
    font-weight: 400;
}

.text-custom-end{
    text-align:right;
}

@media screen and (max-width: 1600px) {

    .cart-wrapper {
        grid-column-gap: 60px;
    }
}


@media screen and (max-width: 1400px) {

.cart-rod-detail h3 {
    font-size: 20px;
}

.cart-rod-detail p {
    font-size: 14px;
    line-height: 26px;
}

.cart-wrapper {
    grid-column-gap: 40px;
}
}

@media screen and (max-width: 1250px) {

.cart-info-btn {
    border: 0;
    background: transparent;
    text-decoration: underline;
    color: #d46a15;
    font-size: 13px;
    font-weight: 500;
}

.cart-wrapper {
    grid-column-gap: 20px;
}

.cart-rod-detail h3 {
    font-size: 18px;
}

.prod-price p {
    font-size: 18px;
    line-height: 30px;
}

.transparent-btn, .transparent-btn:hover {
    font-size: 14px !important;
}
.cart-details-left h3 {
    font-size: 18px;
}

.cart-prod-img {
    width: 100px;
}
}

@media screen and (max-width: 1025px) {
.cart-wrapper {
    grid-template-columns: 1fr 0.4fr;
}

.cart-prod-img {
    width: 80px;
}

.prod-price p {
    line-height: 24px;
}
.cart-prod-name {
    display: block;
    justify-content: flex-start;
    width: 100%;
}
.text-large-start{
    text-align:left !important;
}
.cart-rod-detail {
    margin-bottom: 1rem;
}
}

@media screen and (max-width: 992px) {
    .cart-wrapper {
        grid-template-columns: 1fr;
        grid-row-gap: 15px;
    }
    
}

@media screen and (max-width: 550px) {
.view-cart-product {
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    padding: 10px 0px;
}

.cart-rod-detail h3 {
    line-height: 27px;
}
}