.trackorder-row {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.trackig-order {
    width: 1000px;
    margin: 0 auto;
  }

 
  .status-bar {
    display: flex;
    align-items: center;
    margin-top: 20px;
    position: relative;
    justify-content:space-between;
  }
  
  .status-item {
    position: relative;
    margin-right: 20px;
  }
  
  .status-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .order-details p {
    font-size: 16px;
    margin-bottom: 5px;
    color: #666;
    line-height: 28px;
    font-family: 'Poppins';
}
  
  .status-name {
    margin-left: 5px;
  }
  
  .status-line {
    position: absolute;
    top: 25%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: gray;
    z-index: -1;
  }

  .status-name {
    margin-left: 5px;
    font-weight: 500;
    font-size: 16px;
}


  @keyframes waveAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .status-circle.current-status {
    animation: waveAnimation 1s infinite;
  }
  
  

  @media screen and (max-width: 1100px) {
    .trackig-order {
        width: 800px;
        margin: 0 auto;
      }

  }


  @media screen and (max-width: 850px) {
    .trackig-order {
        width: 100%;
        margin: 0 auto;
      }

  }

  @media screen and (max-width: 768px) {

  .status-line {
    position: absolute;
    top: 0%;
    left: 9px;
    right: 0;
    height: 100%;
    background-color: gray;
    z-index: -1;
    width: 2px;
}

.status-bar {
    display: block;
}

.status-item {
    position: relative;
    margin-right: 20px;
    display: flex;
    height: 80px;
}
.status-bar.status-item:last-child {
    height: 0px !important;
}

.status-name {
    margin-left: 10px;
    font-size: 16px;
}
  }