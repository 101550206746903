.btn-style{
    font-size: 16px !important;
    letter-spacing: 1px;
    line-height: 20px;
    font-weight: 600 !important;
    font-family: "poppins";
    padding: 12px 25px !important;
    text-decoration: none;
    border-radius: 5px;
    text-transform: uppercase;
    transition:all 0.3s linear !important;
    border-width:2px;
    width: inherit;
    text-align:center;
}

@media screen and (max-width: 1500px) {

.btn-style {
    font-size: 14px !important;
    padding: 12px 15px !important;
}
}