
.accordion {
    width: 100%;
  }
  

  .accordion-item {
    border: 1px solid #ccc;
    margin-bottom: 5px;
    background:#cfd8dc78 !important;
  }
  
  .accordion-label {
    display: inline-block;
    cursor: pointer;
    padding: 10px;
    font-weight:600; 
  }
  
 
  .content {
    display: none;
    padding: 18px;
    background-color: #fff; 
  }

  .dark-brown-text{
    color:#770A00;
    font-weight: 500; 
  }

  .content h3 {
    font-size: 18px;
    font-family: 'Poppins';
    line-height: 20px;
    font-weight: 500;
}

.content p {
    font-size: 13px;
    font-family: 'Poppins';
    line-height: 18px;
    font-weight: 300;
    color:#666;
}

.content h4 {
    font-size: 14px;
    color: #d46a15;
    font-weight: 600;
    font-family: 'Poppins';
    line-height: 24px;
}

.form-select:focus {
    border-color: #d46a15 !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
} 
  
  input[type="radio"]:checked + .accordion-label + .content {
    display: block;
  }

.pl-40{ 
  padding-left:40px;
 }
  

 @media screen and (max-width: 1500px) {
  .pl-40{
    padding-left:20px;
 }
      
 }

 @media screen and (max-width: 1250px) {
  .pl-40{
    padding-left:0px;
 }
      
 }
