.banner-info-bg{
    background:#12365A;
}

.banner-info {
    margin: 0 auto;
    text-align: center;
}

.banner-info h1 {
    font-size: 48px;
    color: #fff;
    font-weight: bold;
    line-height: 48px;
    font-family: 'Poppins';
    text-transform: capitalize;
}

.banner-info h3 {
    font-size: 32px;
    color: #eeda0a;
    font-weight: 500;
    line-height:36px;
    font-family: 'Poppins';
    text-transform: capitalize;
}

.banner-info p {
    font-size: 18px;
    color: #fff;
    font-weight: 300;
    line-height:30px;
    font-family: 'Poppins';
    text-transform: capitalize;
    margin:0 auto;
}

.info_card p {
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    
}

.para-width{
    width:742px
}

.icon-list{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    position: absolute;
    top: 40%;
    margin: 0 auto;
    text-align: center;
    left: 0%;
    right: 0;
    padding: 20px;
}

@media screen and (max-width: 1400px) {

.banner-info h1 {
    font-size: 40px;
    line-height: 40px;
}
}
@media screen and (max-width: 770px) {
.banner-info h1 {
    font-size: 28px;
    line-height: 36px;
}
.banner-info h3 {
    font-size: 24px;
    line-height: 28px;
}
.banner-info p {
    font-size: 14px;
    line-height: 26px;
}
.para-width {
    width: 100%;
}

.icon-list {
    top: 30%;
    overflow-x: scroll;
    margin: 15px;
}

.info_card p {
    font-size: 14px;
    line-height: 18px;
    color: #fff;
}
}

@media screen and (max-width: 508px) {
.banner-info h3 {
    font-size: 22px;
    line-height: 28px;
}
}